.contact-custom {
    margin-top: -10%;
}

.btn-primary {
    background-color: #0062ff !important;
    ;
}

.nk-nav-text {
    font-size: 20px !important;
}


  
@media (max-width: 850px) {

    .center-content-mobile {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-x: hidden; /* Prevent horizontal scrolling */
    }

    .center-content-mobile {
      min-height: 100vh; /* Ensure full height on mobile */
    }

    .time-to {
        text-align: center;
    }
        
}

@media (max-width: 850px) {
   .ta h4 {
    font-size: 15px;
    margin-bottom: -10%;
  }

}

@media (min-width: 992px) {
    /* Make the submenu container only as wide as its content */
    .nk-nav-sub.nk-nav-mega,
    .nk-nav-sub.nk-nav-mega-lg {
      display: inline-block !important;
      width: auto !important;
      max-width: none !important;
    }
  
    /* Also override the inner li that has the col-lg-8 class */
    .nk-nav-sub .nk-nav-item.col-lg-8 {
      width: auto !important;
      max-width: none !important;
    }
  }

  @media (min-width: 992px) {
    /* Force the submenu row to stack items vertically */
    .nk-nav-sub ul.row.mx-auto {
      flex-direction: column !important;
    }
    
    /* Override any col-lg-* classes so submenu items take full width */
    .nk-nav-sub li[class*="col-lg-"] {
      flex: none !important;
      width: 100% !important;
      max-width: 100% !important;
    }
  }

  @media (min-width: 992px) {
    /* Ensure the Tools menu item is the positioning context */
    .nk-nav-item.has-sub {
      position: relative;
    }
  
    /* Position the mega submenu directly under the Tools menu */
    .nk-nav-sub.nk-nav-mega,
    .nk-nav-sub.nk-nav-mega-lg {
      position: absolute !important;
      top: 100% !important;    /* Place directly below the parent */
      left: 0 !important;       /* Align with the left edge of the parent */
      transform: none !important;
      display: inline-block !important;
      width: auto !important;
      max-width: none !important;
      margin: 0;
      padding: 0;
    }
  
    /* Force the submenu items to stack vertically */
    .nk-nav-sub ul.row.mx-auto {
      flex-direction: column !important;
    }
  
    /* Override Bootstrap grid classes on submenu items so they take full width */
    .nk-nav-sub li[class*="col-lg-"] {
      flex: none !important;
      width: 100% !important;
      max-width: 100% !important;
    }
  }